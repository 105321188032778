<template>
  <div class="page error">
    <div class="content" style="padding:13px 24px;">
      <ul class="error-pills">
      <li class="step-item">
        
      </li>
      <li class="step-item">
       <img class="userinfo-avatar" src="../assets/image/qrcode_for_gzh.jpg" style="width:254px;height:auto;margin-top:30px;"/>
      </li>
      <li class="step-item">
        <h2>你还未关注‘SayONES’公众号，请长按二维码进行关注!</h2>
      </li>
    </ul>
    </div>
    <!-- <div :class="isIPhoneX ? 'article-footer iIX-css':'article-footer'" >
      <van-button @click="viewJobTap" type="primary" round block>返回首页</van-button>
    </div> -->
  </div>
</template>

<script>  
  import Store from 'store'
  import { mapState } from 'vuex'
  import commonMixin from './../mixin/common'
  import { Empty } from "vant";
  export default {
    name: 'Error',
    components: {
      Empty
    },
    mixins: [commonMixin],
    data () {
      return {
          isIPhoneX:false,
          msg:this.$route.query.msg,
      }
    },
    created() {
        this.isIPhoneX = Store.get('isIPhoneX') || false;
    },
    methods: {
      viewJobTap:function(){
        this.$router.push('/product')
      }
    }
  }
</script>

<style lang="less">
.error{
  .step-item{
    text-align: center;
  }

  .van-button--primary{
      width: 250px;
      background-image: linear-gradient(135deg, #3193d6 0%, #0c45ab 100%)!important;
      background-color: none;
      border: none;
    }
}
</style>
