import { mapState } from 'vuex'
import { Toast } from 'vant'
import Store from 'store'

export default {
  created() {

  },
  data() {
    return {
      refresh: true,
    }
  },
  computed: {
    ...mapState({
      location: state => state.common.location,
    }),
  },
  methods: {
    onBlur() {
      window.scrollTo(0, 0)
    },
    refreshView() {
      this.refresh = false;
      this.$nextTick(() => {
        this.refresh = true;
      })
    },
    goBack() {
      this.$router.go(-1)
    },
    showConfigStatus(type) {
      return this.$config.status[type] || ''
    },
    checkMobile(value) {
      let reg = /^\d{11}$/;
      if(value!== '' && value && !reg.test(value)) {
        Toast.fail('手机号格式错误')
      }
      return !(value!== '' && value && !reg.test(value))
    }
  }
}
